import { Injectable } from '@angular/core';
import themes from 'devextreme/ui/themes';

@Injectable({
  providedIn: 'root',
})
export class DevExtremeThemeService {
  constructor() {
    this.changeTheme(this.isDarkMode);
  }

  public get isDarkMode() {
    const darkMode = localStorage.getItem('darkMode') === 'true';
    return darkMode;
  }

  public changeTheme(isDarkMode: boolean) {
    if (isDarkMode) {
      themes.current('generic.dark');
      localStorage.setItem('dx-theme', 'generic.dark');
    } else {
      themes.current('generic.light');
      localStorage.setItem('dx-theme', 'generic.light');
    }
  }
}
